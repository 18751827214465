"use strict";

class SSConfigService {
    constructor($localStorage) {
        const self = this;

        self.$localStorage = $localStorage;

        self.defAppService = {};
        if (self.$localStorage.def_app_service) {
            self.defAppService = self.$localStorage.def_app_service;
        }

        self.serverConfig = self.defAppService.serverConfig;
        if (!self.serverConfig) {
            self.serverConfig = {};
        }

        // TEMP
        self.serverConfig = SHOWSUITE.data.config;
        self.SINGAPORE_COUNTRY_CODE = 702;
    }

    // Base
    searchArrayE(array, id) {
        const self = this;

        if (!array || !id == null) return "";

        if (typeof array == "string") {
            array = self.serverConfig[array];
        }
        let object = null;
        jQuery.each(array, function(i, e) {
            if (e.id == id) {
                object = e;
            }
        });

        return object;
    }

    searchArray(array, id) {
        const self = this;

        let object = self.searchArrayE(array, id);

        return object ? object.name : "";
    }

    // General

    getCountries() {
        const self = this;

        return self.serverConfig.countries;
    }

    getCountry(country_code) {
        const self = this;

        let country = null;
        jQuery.each(self.getCountries(), function(i, e) {
            if (e.country_code == country_code) {
                country = e;
            }
        });

        return country;
    }

    getCountryName(country_code) {
        const self = this;

        let country = self.getCountry(country_code);

        return country ? country.name : "";
    }

    getRegions(country_code) {
        const self = this;

        let country = null;
        jQuery.each(self.getCountries(), function(i, e) {
            if (e.country_code == country_code) {
                country = e;
            }
        });

        return country ? country.regions : [];
    }

    getNationalities() {
        const self = this;

        return self.serverConfig.nationalities;
    }

    getNationalityName(country_code) {
        const self = this;

        let country = self.getCountry(country_code);

        return country ? country.citizenship : "";
    }

    getGenders(ec_filtered = false) {
        const self = this;

        let genders = null;

        if (ec_filtered) {
            genders = self.serverConfig.genders_ec;
        } else {
            genders = self.serverConfig.genders;
            for (var x in genders)
                genders[x].name == "Undisclosed"
                    ? genders.push(genders.splice(x, 1)[0])
                    : 0;
        }

        return genders;
    }
    getExtMappingKeys() {
        const self = this;

        return self.serverConfig.ext_mapping_keys;
    }
    getExtMappingKeyValue(key) {
        const self = this;
        return self.serverConfig.ext_mapping_keys[key];
    }

    getGenderName(id, ec_filtered = false) {
        const self = this;

        return self.searchArray(self.getGenders(ec_filtered), id);
    }

    getEducationLevels() {
        const self = this;
        return self.serverConfig.education_levels;
    }
    getEducationLevelName(id) {
        const self = this;
        return self.searchArray(self.getEducationLevels(), id);
    }
    getEmploymentStatuses() {
        const self = this;
        return self.serverConfig.employment_statuses;
    }
    getEmploymentStatusName(id) {
        const self = this;
        return self.searchArray(self.getEmploymentStatuses(), id);
    }

    getBanks(countryCode) {
        const self = this;

        let banks = self.serverConfig.banks;
        if(!countryCode) countryCode = self.SINGAPORE_COUNTRY_CODE; // Default is singapore

        if(countryCode){
            let _banks = [];
            banks.forEach(bank => {
                if(bank.name == 'OTHERS') bank.country = countryCode;
                // Fetch only those banks which has given 'countryCode'
                if(bank.country.indexOf(countryCode) != -1){
                    _banks.push(bank);
                }
            });
            if(!_banks.find(x => x.name == 'OTHERS'))
                _banks.push({id: 9999, code: 'OTHERS', name: 'OTHERS'});
            
            banks = _banks;
        }

        return banks;
    }

    getPropertyAccountTypes() {
        const self = this;

        return self.serverConfig.property_account_types;
    }

    getMrts() {
        const self = this;

        return self.serverConfig.mrt_stations;
    }

    getSchools() {
        const self = this;

        return self.serverConfig.schools;
    }

    // Property

    /**
     * @deprecated
     * @param code
     * @returns {*}
     */
    getPropertyStatuses() {
        return this.serverConfig.property_statuses;
    }

    getPropertyStatusText(code) {
        return this.searchArray("property_statuses", code);
    }

    getPropertyStatusName(code) {
        return this.searchArray("property_statuses", code);
    }

    getPropertyCategoryName(code) {
        return this.searchArray("property_categories", code);
    }

    getPropertyTypeName(code) {
        return this.searchArray("property_types", code);
    }

    getPropertyProductName(code) {
        return this.searchArray("property_products", code);
    }

    // Unit

    getUnitStatusName(code) {
        return this.searchArray("unit_statuses", code);
    }

    getUnitCategoryName(code) {
        return this.searchArray("unit_categories", code);
    }

    getUnitTypeName(code) {
        return this.searchArray("unit_types", code);
    }

    getUnitPriceTypeName(code) {
        return this.searchArray("unit_price_types", code);
    }

    getUnitKitchenTypeName(code) {
        return this.searchArray("unit_kitchen", code);
    }

    getUnitFlooringTypeName(code) {
        return this.searchArray("unit_flooring", code);
    }

    getUnitTilesTypeName(code) {
        return this.searchArray("unit_tiles", code);
    }

    // Notification

    getNotificationGroupName(code) {
        return this.searchArray("notification_groups", code);
    }

    getNotificationTypeName(code) {
        return this.searchArray("notification_types", code);
    }

    getBuyerRelationships(ec_filtered = false) {
        const self = this;

        if (ec_filtered) return self.serverConfig.relationships_ec;
        else return self.serverConfig.relationships;
    }
    getBuyerRelationshipName(code, ec_filtered = false) {
        const self = this;

        return self.searchArray(self.getBuyerRelationships(ec_filtered), code);
    }

    getRaces(ec_filtered = false) {
        const self = this;

        if (ec_filtered) return self.serverConfig.races_ec;
        return self.serverConfig.races;
    }

    getRaceName(code, ec_filtered = false) {
        const self = this;

        return self.searchArray(self.getRaces(ec_filtered), code);
    }

    getMaritalStatuses(ec_filtered = false) {
        const self = this;
        if (ec_filtered) return self.serverConfig.marital_statuses_ec;
        return self.serverConfig.marital_statuses;
    }

    getMaritalStatusName(id, ec_filtered = false) {
        const self = this;

        return self.searchArray(self.getMaritalStatuses(ec_filtered), id);
    }

    getHouseholdTypes() {
        const self = this;
        return self.serverConfig.household_types;
    }

    getHouseholdTypeName(code) {
        const self = this;

        return self.searchArray(self.getHouseholdTypes(), code);
    }

    getAbortReasons() {
        const self = this;

        return self.serverConfig.abort_reasons;
    }

    getBouncedReasons() {
        const self = this;

        return self.serverConfig.bounced_reasons;
    }

    getAbortReasonName(code) {
        const self = this;

        let name = self.searchArray(self.getAbortReasons(), code);

        if (!name) {
            name = self.getReissueReasonName(code);
        }

        return name;
    }

    getPaymentClauseName(code) {
        const self = this;

        let clauseName = self.searchArray(
            self.serverConfig.payment_types,
            code
        );

        if (typeof clauseName == "string") {
            return clauseName;
        } else {
            return "--";
        }
    }

    getPaymentClauses() {
        const self = this;

        return self.serverConfig.payment_types;
    }

    getReissueReasons() {
        const self = this;

        return self.serverConfig.reissue_reasons;
    }

    getReissueReasonName(code) {
        const self = this;

        return self.searchArray(self.getReissueReasons(), code);
    }

    getReissueTypes() {
        const self = this;

        return self.serverConfig.reissue_types;
    }

    getReissueTypeName(code) {
        const self = this;

        return self.searchArray(self.getReissueTypes(), code);
    }

    getPurchaseModes() {
        const self = this;

        return self.serverConfig.purchase_modes;
    }

    getPurchaseModeName(code) {
        const self = this;

        return self.searchArray(self.getPurchaseModes(), code);
    }

    getSaleTypes() {
        const self = this;

        return self.serverConfig.sales_types;
    }

    getSaleTypeName(code) {
        const self = this;

        return self.searchArray(self.getSaleTypes(), code);
    }

    //-------------------------------------------

    getReferralTypes() {
        const self = this;

        return self.serverConfig.referral_types;
    }

    getReferralTypeName(code) {
        const self = this;

        return self.searchArray(self.getReferralTypes(), code);
    }

    getReferralCodeLabel(referralType) {
        const self = this;

        if (referralType == 10) {
            // const REFERRAL_TYPE_STAFF = 10;
            return 'Staff ID';
        } else if (referralType == 11) {
            // const REFERRAL_TYPE_BUYER = 11;
            return 'NRIC / Passport No.';
        } else if (referralType == 99) {
            // const REFERRAL_TYPE_OTHER = 99;
            return 'Vendor Code';
        } else {
            // Default
            return 'Code';
        }
    }

    //-------------------------------------------

    getResidenceTypes(countryCode) {
        const self = this;

        let residenceTypes = self.serverConfig.residence_types;
        if(!countryCode) countryCode = self.SINGAPORE_COUNTRY_CODE; // Default is singapore
        
        if(countryCode){
            let types = [];

            residenceTypes.forEach( type => {
                if(type.country.indexOf(countryCode) != -1){
                    types.push(type);
                }
            });

            residenceTypes = types;
        }

        return residenceTypes;
    }

    getResidenceTypeName(code) {
        const self = this;

        return self.searchArray(self.getResidenceTypes(), code);
    }

    getSalutations() {
        const self = this;

        return self.serverConfig.salutations;
    }

    getSalutationName(code) {
        const self = this;

        if (!code) return null;

        if (isNaN(parseInt(code))) return code;

        return self.searchArray(self.getSalutations(), code);
    }

    // ------------------------------------------

    getDiscountTypes() {
        const self = this;

        return self.serverConfig.discount_types;
    }

    getDiscountTypeCode(type) {
        const self = this;

        let o = self.searchArrayE(self.getDiscountTypes(), type);

        return o ? o.code : "";
    }

    getDiscountTypeName(type) {
        const self = this;

        return self.searchArray(self.getDiscountTypes(), type);
    }
    // ------------------------------------------

    getLawFirms() {
        const self = this;
        return self.serverConfig.lawyers;
    }

    getCommissionTypes() {
        const self = this;

        return self.serverConfig.commission_types;
    }

    getCommissionTypeName(type) {
        const self = this;

        return self.searchArray(self.getCommissionTypes(), type);
    }

    getPaymentPlanClauseRuleStartFromTypes() {
        const self = this;

        return self.serverConfig.payment_plan_clause_rule_start_from_types;
    }

    getPaymentPlanClauseRuleTypeName(type) {
        const self = this;

        return self.searchArray(
            self.getPaymentPlanClauseRuleStartFromTypes(),
            type
        );
    }

    getIdentityTypes(countryCode){
        const self = this;

        if(!countryCode) countryCode = self.SINGAPORE_COUNTRY_CODE; // Default is singapore

        let identityTypes = self.serverConfig.identity_types;

        if(countryCode){
            identityTypes = identityTypes[countryCode];
        }

        return identityTypes;
    }

    getCountryDistricts(countryCode){
        const self = this;

        let districts = [];

        let country = self.getCountry(countryCode);
        if(country){
            districts = country.regions;
        }

        return districts;
    }

    getPPFormRequiredFields() {
        let fields = {
            email: { label: "Email", required: true, mandatory: true, visible: true },
            phone1: {
                label: "Primary Contact Number",
                required: true,
                mandatory: true,
                visible: true
            },
            title: { label: "Salutation", required: false, visible: true },
            first_name: { label: "First Name", required: true, visible: true },
            last_name: { label: "Last Name", required: true, visible: true },
            legal_name: { label: "Name as on IC/Passport", required: true, mandatory: true, visible: true },
            identity_type: { label: "Identity Type", required: true, mandatory: true, visible: true },
            company_name: { label: "Company Name", required: true, mandatory: true, visible: true },
            company_registration_no: {
                label: "Company Registration No.",
                required: true,
                mandatory: true,
                visible: true
            },
            company_email: { label: "Company Email", required: true, visible: true },
            company_phone1: { label: "Company Mobile No.", required: true, visible: true },
            company_phone2: { label: "Company Contact No.", required: false, visible: true },
            company_country_of_origin: {
                label: "Company Country Of Origin",
                required: false,
                visible: true
            },
            nationality: { label: "Nationality", required: true, visible: true },
            country_of_origin: { label: "Country Of Origin/Birth", required: false, visible: true },
            passport_no: {
                label: "Passport No.",
                required: false,
                visible: true,
                comment: "Required if nationality foreigner"
            },
            national_id: {
                label: "NRIC",
                required: false,
                visible: true,
                comment: "Required if nationality SG/SG-PR"
            },
            foreigner_national_id: {
                label: "Foreigner National ID",
                required: false,
                visible: true,
                comment: "Required if nationality Foreigner"
            },
            passport_issued_by:{
                label: "Passport Issued By",
                required: false,
                visible: true,
                comment: "show/hide on basis of nationality"
            },
            passport_issued_date:{
                label: "Passport Issued Date",
                required: false,
                visible: true,
                comment: "show/hide on basis of nationality"
            },
            passport_expiry_date:{
                label: "Passport Expiry Date",
                required: false,
                visible: true,
                comment: "show/hide on basis of nationality"
            },

            race: {
                label: "Race",
                required: false,
                visible: true,
                comment: "Show/Hide on the basis of nationality"
            },
            industry: { label: "Industry", required: true, visible: true },
            occupation: { label: "Occupation", required: true, visible: true },
            gender: { label: "Gender", required: true, visible: true },
            dob: { label: "Date of Birth", required: true, visible: true },
            marital_status: { label: "Marital Status", required: false, visible: true },
            monthly_income: { label: "Monthly Income", required: false, visible: true},
            employment_status: { label: "Employment Status", required: false, visible: true},
            education_level: { label: "Education Level", required: false, visible: true},
            spouse_national_id: { label: "Spouse National ID", required: false, visible: true},
            phon2: { label: "Secondary Contact Number", required: false, visible: true },
            residence_type: { label: "Residence Type", required: false, visible: true },
            primary_address_line1: {
                label: "Primary Address House/Block",
                required: true, visible: true
            },
            primary_address_line2: {
                label: "Primary Address Street Name",
                required: false, visible: true
            },
            primary_address_unit_no: {
                label: "Primary Address Unit No.",
                required: false, visible: true
            },
            primary_address_postcode: {
                label: "Primary Address Postcode",
                required: false, visible: true
            },
            primary_address_city: {
                label: "Primary Address City",
                required: false, visible: true
            },
            primary_address_province: {
                label: "Primary Address Province",
                required: false, visible: true
            },
            primary_address_district: {
                label: "Primary Address District",
                required: false, visible: true
            },
            primary_address_country: {
                label: "Primary Address Country",
                required: true, visible: true
            }
        };

        return fields;
    }

    getEOIFormRequiredFields() {
        let fields = {
            email: { label: "Email", required: true, mandatory: true, visible: true },
            phone1: {
                label: "Primary Contact Number",
                required: true,
                mandatory: true,
                visible: true
            },
            title: { label: "Salutation", required: false, visible: true },
            first_name: { label: "First Name", required: true, visible: true },
            last_name: { label: "Last Name", required: true, visible: true },
            legal_name: { label: "Name as on IC/Passport", required: true, mandatory: true, visible: true },
            identity_type: { label: "Identity Type", required: true, mandatory: true, visible: true },
            company_name: { label: "Company Name", required: true, mandatory: true, visible: true },
            company_registration_no: {
                label: "Company Registration No.",
                required: true,
                mandatory: true,
                visible: true
            },
            company_email: { label: "Company Email", required: true, visible: true },
            company_phone1: { label: "Company Mobile No.", required: true, visible: true },
            company_phone2: { label: "Company Contact No.", required: false, visible: true },
            company_country_of_origin: {
                label: "Company Country Of Origin",
                required: false,
                visible: true
            },
            nationality: { label: "Nationality", required: true, visible: true },
            country_of_origin: { label: "Country Of Origin/Birth", required: false, visible: true },
            passport_no: {
                label: "Passport No.",
                required: false,
                visible: true,
                comment: "Required if nationality foreigner"
            },
            national_id: {
                label: "NRIC",
                required: false,
                visible: true,
                comment: "Required if nationality SG/SG-PR"
            },
            foreigner_national_id: {
                label: "Foreigner National ID",
                required: false,
                visible: true,
                comment: "Required if nationality Foreigner"
            },
            passport_issued_by:{
                label: "Passport Issued By",
                required: false,
                visible: true,
                comment: "show/hide on basis of nationality"
            },
            passport_issued_date:{
                label: "Passport Issued Date",
                required: false,
                visible: true,
                comment: "show/hide on basis of nationality"
            },
            passport_expiry_date:{
                label: "Passport Expiry Date",
                required: false,
                visible: true,
                comment: "show/hide on basis of nationality"
            },

            race: {
                label: "Race",
                required: false,
                visible: true,
                comment: "Show/Hide on the basis of nationality"
            },
            industry: { label: "Industry", required: true, visible: true },
            occupation: { label: "Occupation", required: true, visible: true },
            gender: { label: "Gender", required: true, visible: true },
            dob: { label: "Date of Birth", required: true, visible: true },
            marital_status: { label: "Marital Status", required: false, visible: true },
            monthly_income: { label: "Monthly Income", required: false, visible: true},
            employment_status: { label: "Employment Status", required: false, visible: true},
            education_level: { label: "Education Level", required: false, visible: true},
            spouse_national_id: { label: "Spouse National ID", required: false, visible: true},
            phon2: { label: "Secondary Contact Number", required: false, visible: true },
            residence_type: { label: "Residence Type", required: false, visible: true },
            primary_address_line1: {
                label: "Primary Address House/Block",
                required: true, visible: true
            },
            primary_address_line2: {
                label: "Primary Address Street Name",
                required: false, visible: true
            },
            primary_address_unit_no: {
                label: "Primary Address Unit No.",
                required: false, visible: true
            },
            primary_address_postcode: {
                label: "Primary Address Postcode",
                required: false, visible: true
            },
            primary_address_city: {
                label: "Primary Address City",
                required: false, visible: true
            },
            primary_address_province: {
                label: "Primary Address Province",
                required: false, visible: true
            },
            primary_address_district: {
                label: "Primary Address District",
                required: false, visible: true
            },
            primary_address_country: {
                label: "Primary Address Country",
                required: true, visible: true
            }
        };

        return fields;
    }

    getWitnessFormRequiredFields(){

        let fields = {
            email: { label: "Email", required: true, mandatory: true, visible: true },
            phone1: {label: "Primary Contact Number", required: true, mandatory: true, visible: true },
            title: { label: "Salutation", required: false, visible: false },
            first_name: { label: "First Name", required: false, visible: false },
            last_name: { label: "Last Name", required: false, visible: false },
            legal_name: { label: "Name as on IC/Passport", required: true, mandatory: true, visible: true },
            identity_type: { label: "Identity Type", required: true, mandatory: true, visible: true },
            company_name: { label: "Company Name", required: false, visible: false },
            company_registration_no: {
                label: "Company Registration No.",
                required: false,
                visible: false
            },
            company_email: { label: "Company Email", required: false, visible: false },
            company_phone1: { label: "Company Mobile No.", required: false, visible: false },
            company_phone2: { label: "Company Contact No.", required: false, visible: false },
            company_country_of_origin: {
                label: "Company Country Of Origin",
                required: false,
                visible: false
            },
            nationality: { label: "Nationality", required: false, visible: false },
            country_of_origin: { label: "Country Of Origin/Birth", required: false, visible: false },
            passport_no: {
                label: "Passport No.",
                required: false,
                visible: true,
                comment: "Required if nationality foreigner"
            },
            national_id: {
                label: "NRIC",
                required: false,
                visible: true,
                comment: "Required if nationality SG/SG-PR"
            },
            foreigner_national_id: {
                label: "Foreigner National ID",
                required: false,
                visible: false,
                comment: "Required if nationality Foreigner"
            },
            passport_issued_by:{
                label: "Passport Issued By",
                required: false,
                visible: false,
                comment: "show/hide on basis of nationality"
            },
            passport_issued_date:{
                label: "Passport Issued Date",
                required: false,
                visible: false,
                comment: "show/hide on basis of nationality"
            },
            passport_expiry_date:{
                label: "Passport Expiry Date",
                required: false,
                visible: false,
                comment: "show/hide on basis of nationality"
            },
            race: {
                label: "Race",
                required: false,
                visible: false,
                comment: "Show/Hide on the basis of nationality"
            },
            industry: { label: "Industry", required: false, visible: false},
            occupation: { label: "Occupation", required: false, visible: false},
            gender: { label: "Gender", required: false, visible: false, mandatory: false },
            dob: { label: "Date of Birth", required: false, visible: false },
            marital_status: { label: "Marital Status", required: false, visible: false, mandatory: false },
            monthly_income: { label: "Monthly Income", required: false, visible: false, mandatory: false},
            employment_status: { label: "Employment Status", required: false, visible: false, mandatory: false},
            education_level: { label: "Education Level", required: false, visible: false, mandatory: false},
            spouse_national_id: { label: "Spouse National ID", required: false, visible: false, mandatory: false},
            phon2: { label: "Secondary Contact Number", required: false, visible: false },
            relationship_with_buyer_1: { label: "Relationship with Buyer 1", required: true, visible: true, mandatory: false},
            residence_type: { label: "Residence Type", required: false, visible: false, mandatory: false },
            primary_address_line1: {
                label: "Primary Address House/Block",
                required: false, visible: false
            },
            primary_address_line2: {
                label: "Primary Address Street Name",
                required: false, visible: false
            },
            primary_address_unit_no: {
                label: "Primary Address Unit No.",
                required: false, visible: false
            },
            primary_address_postcode: {
                label: "Primary Address Postcode",
                required: false, visible: false
            },
            primary_address_city: {
                label: "Primary Address City",
                required: false, visible: false
            },
            primary_address_province: {
                label: "Primary Address Province",
                required: false, visible: false, mandatory: false
            },
            primary_address_district: {
                label: "Primary Address District",
                required: false, visible: false, mandatory: false
            },
            primary_address_country: {
                label: "Primary Address Country",
                required: false, visible: false
            }
        };

        return fields;
    }

    getWitnessSettings(){
        let fields = {
            enable: 1,
        };

        return fields;
    }

    getSaleOTPRepresentativeSettings() {
        return {
            enable: 1,
        };
    }

    getNonBuyerPayeeFormRequiredFields() {
        let fields = {
            email: { label: "Email", required: true, mandatory: true, visible: true },
            phone1: {label: "Primary Contact Number", required: true, mandatory: true, visible: true },
            title: { label: "Salutation", required: true, visible: true },
            first_name: { label: "First Name", required: true, visible: true },
            last_name: { label: "Last Name", required: true, visible: true },
            legal_name: { label: "Name as on IC/Passport", required: true, mandatory: true, visible: true },
            identity_type: { label: "Identity Type", required: true, mandatory: true, visible: true },
            company_name: { label: "Company Name", required: true, mandatory: true, visible: true },
            company_registration_no: {
                label: "Company Registration No.",
                required: true,
                mandatory: true,
                visible: true
            },
            company_email: { label: "Company Email", required: true, visible: true },
            company_phone1: { label: "Company Mobile No.", required: true, visible: true },
            company_phone2: { label: "Company Contact No.", required: false, visible: true },
            company_country_of_origin: {
                label: "Company Country Of Origin",
                required: false,
                visible: true
            },
            nationality: { label: "Nationality", required: true, visible: true, mandatory: true },
            country_of_origin: { label: "Country Of Origin/Birth", required: true, visible: true },
            passport_no: {
                label: "Passport No.",
                required: false,
                visible: true,
                comment: "Required if nationality foreigner"
            },
            national_id: {
                label: "NRIC",
                required: false,
                visible: true,
                comment: "Required if nationality SG/SG-PR"
            },
            foreigner_national_id: {
                label: "Foreigner National ID",
                required: false,
                visible: true,
                comment: "Required if nationality Foreigner"
            },
            passport_issued_by:{
                label: "Passport Issued By",
                required: false,
                visible: true,
                comment: "show/hide on basis of nationality"
            },
            passport_issued_date:{
                label: "Passport Issued Date",
                required: false,
                visible: true,
                comment: "show/hide on basis of nationality"
            },
            passport_expiry_date:{
                label: "Passport Expiry Date",
                required: true,
                visible: true,
                comment: "show/hide on basis of nationality"
            },
            race: {
                label: "Race",
                required: false,
                visible: false,
                mandatory: true,
                comment: "Show/Hide on the basis of nationality"
            },
            industry: { label: "Industry", required: true, visible: true},
            occupation: { label: "Occupation", required: true, visible: true},
            gender: { label: "Gender", required: true, visible: true, mandatory: false },
            dob: { label: "Date of Birth", required: true, visible: true },
            marital_status: { label: "Marital Status", required: false, visible: false, mandatory: true },
            monthly_income: { label: "Monthly Income", required: false, visible: false, mandatory: true},
            employment_status: { label: "Employment Status", required: false, visible: false, mandatory: true},
            education_level: { label: "Education Level", required: false, visible: false, mandatory: true},
            spouse_national_id: { label: "Spouse National ID", required: false, visible: false, mandatory: true},
            phon2: { label: "Secondary Contact Number", required: false, visible: true },
            relationship_with_buyer_1: { label: "Relationship with Buyer 1", required: true, visible: true, mandatory: true},
            residence_type: { label: "Residence Type", required: false, visible: false, mandatory: true },
            primary_address_line1: {
                label: "Primary Address House/Block",
                required: true, visible: true
            },
            primary_address_line2: {
                label: "Primary Address Street Name",
                required: false, visible: true
            },
            primary_address_unit_no: {
                label: "Primary Address Unit No.",
                required: false, visible: true
            },
            primary_address_postcode: {
                label: "Primary Address Postcode",
                required: false, visible: true
            },
            primary_address_city: {
                label: "Primary Address City",
                required: false, visible: true
            },
            primary_address_province: {
                label: "Primary Address Province",
                required: false, visible: false, mandatory: true
            },
            primary_address_district: {
                label: "Primary Address District",
                required: false, visible: false, mandatory: true
            },
            primary_address_country: {
                label: "Primary Address Country",
                required: true, visible: true
            }
        };
        return fields;
    }

    getNonBuyerPayeeSettings(){
        let fields = {
            enable: 1,
        };
        return fields;
    }

    getTimeZones(){
        const self = this;

        let timeZones = []; 

        try{

            let momentZones = moment.tz.names();
            
            momentZones.forEach((timeZone) => {
              let gmt = moment().tz(timeZone).format('Z');
              let abbr = moment().tz(timeZone).zoneAbbr();

              if($.isNumeric(abbr)) abbr = "";

              if(!abbr && timeZone.includes('Singapore')) abbr = "SGT";
              
              timeZones.push({
                id: timeZone, 
                label: abbr, 
                name: `${timeZone}${abbr? ' - '+abbr : ''} (${gmt})`, 
                time: gmt
              });
            });

        }
        catch(ex){
            console.log(ex);
        }

        return timeZones;
    }
}

SSConfigService.$inject = ["$localStorage"];
app.service("SSConfigService", SSConfigService);
